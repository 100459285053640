<template>
  <div class="main">

    <!-- alert banner -->
    <b-alert variant="info" v-if="info" show>{{ info }}</b-alert>
    <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

    <div v-if="loadingBuilds" class="loading">
      <b-spinner type="grow" label="Loading..."></b-spinner> Loading jobs...
    </div>
    <b-form @submit="onSubmit" @reset="onReset" v-on:change="invalidateForm" v-else-if="show">

      <b-form-group
        label="Customer Short Name"
        label-for="shortName-input"
        description="Must match a customer already in portal"
      >
        <b-form-input
          id="shortName-input"
          v-model="form.shortName"
          placeholder="my company..."
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="AWS Access Key ID*"
        label-for="accessKey-input"
        description="AWS access key ID for target account"
      >
        <b-form-input
          id="accessKey-input"
          v-model="form.accessKeyId"
          placeholder="AKIA..."
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="AWS Secret Access Key"
        label-for="secretKey-input"
        description="AWS secret access key for target account"
      >
        <b-form-input
          id="secretKey-input"
          type="password"
          v-model="form.secretKey"
          placeholder="..."
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="AWS Session Token"
        label-for="sessionToken-input"
        description="(Optional) AWS Session Token"
      >
        <b-form-input
          id="sessionToken-input"
          v-model="form.sessionToken"
          type="password"
          placeholder="optional..."
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Builds" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="form.checkedBuilds"
          id="checkboxes-4"
          :aria-describedby="ariaDescribedby"
        >
          <div class="o2-checkbox-grid">
            <b-form-checkbox 
              v-for="build in builds"
              :key="build"
              :value="build">
              {{ build === 'Sumo' ? 'Sumo Logic' : build }}
            </b-form-checkbox>
          </div> 
          <b-row>
            <b-col/>
            <b-button pill variant="info" v-if="form.checkedBuilds.length < builds.length" @click="selectAllBuilds">all <b-icon icon="check2-all" /></b-button>
            <b-button pill v-else @click="selectNoBuilds">none <b-icon icon="x-circle" /></b-button>
          </b-row>
        </b-form-checkbox-group>
      </b-form-group>


      <b-form-group label="AWS Regions" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="form.checkedRegions"
          :aria-describedby="ariaDescribedby"
        >
          <div class="o2-checkbox-grid">
            <b-form-checkbox 
              v-for="region in awsRegions"
              :key="region"
              :value="region">{{ region }}
            </b-form-checkbox>
          </div>
        </b-form-checkbox-group>
      </b-form-group>
      <b-row>
        <b-col/>
        <b-button pill variant="info" v-if="form.checkedRegions.length < awsRegions.length" @click="selectAllRegions">all <b-icon icon="check2-all" /></b-button>
        <b-button pill v-else @click="selectNoRegions">none <b-icon icon="x-circle" /></b-button>
      </b-row>

      <b-form-group
        id="input-group-2"
        v-if="form.checkedBuilds.includes('AccountAlias')"
        label="Account Alias"
        label-for="input-2"
        description="Must match existing alias (if one already exists)"
      >
        <b-form-input
          id="input-2"
          v-model="form.alias"
          placeholder="my AWS account..."
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group 
        v-if="form.checkedBuilds.includes('AwsServices')" 
        label="Root Account Email" 
        label-for="root-email-input"
        description="Root account email for the AWS Account to store in Portal"
      >
        <b-form-input
          id="root-email-input"
          v-model="form.rootEmail"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group 
        v-if="form.checkedBuilds.includes('AwsServices')" 
        label="Root Account Password" 
        label-for="root-password-input"
        description="Root account password for the AWS Account to store in Portal"
      >
        <b-form-input
          id="root-password-input"
          v-model="form.rootPassword"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group v-if="form.checkedBuilds.includes('BlueMatador')" label="Blue Matador Ops Genie Team" label-for="bm-team-input">
        <b-form-select
          id="bm-team-input"
          v-model="form.bmTeam"
          :options="bmTeams"
          required
        ></b-form-select>
      </b-form-group>
      <b-form-group v-if="form.checkedBuilds.includes('BlueMatador')" label="Blue Matador Ops Genie Priority" label-for="bm-priority-input">
        <b-form-select
          id="bm-priority-input"
          v-model="form.bmPriority"
          :options="bmPriorities"
          required
        ></b-form-select>
      </b-form-group>
      
      <br>
      <b-row class="perseus-form-controls"> 

        <!-- submit button for form -->
        <b-button v-if="!isSubmitted && formValidated && !loadingValidation" pill size="lg" type="submit" variant="info">Submit</b-button>
        <div v-if="isSubmitted" class="loading">
          <b-spinner type="grow" label="Creating job..."></b-spinner> Creating job...
        </div>

        <!-- validate credentials button for form -->
        <b-button v-if="!loadingValidation && !isSubmitted" pill size="lg" @click="validateForm" variant="warning">Validate</b-button>
        <div v-if="loadingValidation" class="loading">
          <b-spinner type="grow" label="Checking credentials..."></b-spinner> Checking credentials...
        </div>
      </b-row>

      <!-- validation card -->
      <div>
        <b-card title="Form Validation" class="mb-2" variant="warning">
          <code v-if="this.formValidated"><b-icon icon="check-square" variant="success"></b-icon> {{ this.identity }}</code>
          <div v-else>Form is not validated</div>
          <code v-if="this.validationError"><b-icon icon="x-circle" variant="danger"></b-icon> {{ this.validationError }}</code>
        </b-card>
      </div>

      <!-- created job cards -->
      <div v-for="job in createdJobs" :key="job.JobId">
        <b-card title="Created job" class="mb-2" variant="warning">
          <code>
            <b-icon icon="check-square" variant="success"></b-icon> 
              Successfully created job {{ job.JobId }} at {{ job.timestamp }}
              <a href="#" @click="openJobPage(job)"><b-icon icon="box-arrow-up-right" /></a>
          </code>  
        </b-card>
      </div>

    </b-form>
    <br>
    <br>
  </div>
</template>

<script>
import mixins from '@/mixins';
export default {
  mixins: [mixins],
  props: {
    msg: String,
  },
  data() {
    return {

      // error or info for banner
      error: "",
      info: "",

      isSubmitted: false,
      loadingBuilds: true,
      builds: [],
      awsRegions: this.getAwsRegions(),
      
      identity: null,
      formValidated: false,
      loadingValidation: false,
      validationError: null,
      createdJobs: [],
      form: {
        shortName: "",
        rootEmail: "",
        rootPassword: "",
        accessKeyId: "",
        secretKey: "",
        sessionToken: "",
        checkedBuilds: [],
        checkedRegions: []
      },
      bmPriorities: [
        "P3",
        "P4"
      ],
      bmTeams: [
        "NOC",
        "AWS"
      ],
      show: true,
    };
  },
  mounted () {
    this.automationApiRequest(`/perseus/builds/aws`)
      .then(data =>{
        this.builds = data
        this.loadingBuilds = false
      })
      .catch(err => {
        this.error = this.getErrorString(err)
        this.loadingBuilds = false
      })

  },
  methods: {
    getJobPayload(){

      let dataBody = {
          CustomerShortName: this.form.shortName.trim(),
          Action: "apply",
          Builds: this.form.checkedBuilds,
          ManagedRegions: this.form.checkedRegions,
          CustomerCredentials: {
            aws_access_key_id: this.form.accessKeyId.trim(),
            aws_secret_access_key: this.form.secretKey.trim()
          }
        }

      if (this.form.sessionToken) {
        dataBody.CustomerCredentials.aws_session_token = this.form.sessionToken.trim()
      }

      if (this.form.checkedBuilds.includes('AccountAlias')) {
        dataBody.AccountAlias = this.form.alias.trim()
      }

      if (this.form.checkedBuilds.includes('BlueMatador')) {
        dataBody.BlueMatadorOpsGenieTeam = this.form.bmTeam
      }

      if (this.form.checkedBuilds.includes('BlueMatador')) {
        dataBody.BlueMatadorOpsGeniePriority = this.form.bmPriority
      }

      if (this.form.checkedBuilds.includes('AwsServices')) {
        dataBody.RootAccountEmail = this.form.rootEmail.trim()
      }

      if (this.form.checkedBuilds.includes('AwsServices')) {
        dataBody.RootAccountPassword = this.form.rootPassword.trim()
      }

      return dataBody

    },
    getValidationPayload(){

      let dataBody = {
          CustomerCredentials: {
            aws_access_key_id: this.form.accessKeyId.trim(),
            aws_secret_access_key: this.form.secretKey.trim()
          }
        }

      if (this.form.sessionToken) {
        dataBody.CustomerCredentials.aws_session_token = this.form.sessionToken.trim()
      }

      if (this.form.checkedBuilds.includes('AccountAlias')) {
        dataBody.AccountAlias = this.form.alias.trim()
      }

      return dataBody
    },
    onSubmit(event) {

      event.preventDefault();

      this.isSubmitted = true;

      // invalidate the form to require revalidation for rerun
      this.invalidateForm()

      // submit the request
      this.automationApiRequest(
        '/perseus/jobs',
        'post',
        this.getJobPayload()
      ).then(response => {

        // mark as not submitted to allow for eventual rerun
        this.isSubmitted = false

        // update last job ID to allow for job link
        response.timestamp = new Date()
        this.createdJobs.unshift(response)

        // reset errors
        this.error = ""
        this.validationError = ""

      }).catch(err => {
        this.isSubmitted = false
        this.error = this.getErrorString(err)
      })
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checkedBuilds = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    invalidateForm() {
      this.formValidated = false;
      this.identity = null;
      return true;
    },
    validateForm() {

      // now identity is loading
      this.loadingValidation = true;
      this.validationError = null;

      if (this.form.checkedBuilds.length == 0){
        this.validationError = "No builds selected"
        this.formValidated = false
        this.loadingValidation = false
        return
      }

      if (!(this.form.shortName || "").trim()){
        this.validationError = "Please fill out the CustomerShortName field"
        this.formValidated = false
        this.loadingValidation = false
        return
      }

      // confirm required fields are filled for validation
      if (this.form.checkedBuilds.includes('AccountAlias')) {
        if (!(this.form.alias || "").trim()){
          this.validationError = "Please fill out the AcccountAlias field"
          this.formValidated = false
          this.loadingValidation = false
          return
        }
      }

      // post credentials to perseus to get current identity
      this.automationApiRequest(
        '/perseus/jobs/validate',
        'post',
        this.getJobPayload()
      ).then(response => {
        
        // update identity
        this.formValidated = true;
        this.identity = response.Identity;
        this.loadingValidation = false;

        // reset errors
        this.error = ""
        this.validationError = ""
        
      }).catch(err => {
        this.formValidated = false;
        this.identity = null;
        this.error = this.getErrorString(err);
        this.validationError = this.getErrorString(err);
        this.loadingValidation = false;
      })

    },
    openJobPage(job) {
      let lastJobPage = this.$router.resolve({name: 'PerseusJob', params: {jobId: job.JobId}});
      window.open(lastJobPage.href, '_blank');
    },
    selectAllBuilds() {
      this.form.checkedBuilds = this.builds
    },
    selectNoBuilds() {
      this.form.checkedBuilds = [];
    },
    selectAllRegions() {
      this.form.checkedRegions = this.awsRegions
    },
    selectNoRegions() {
      this.form.checkedRegions = [];
    }
  },
};
</script>

<style>
.perseus-form-controls .btn {
  margin-right: 1.5rem;
}

.perseus-form-controls {
  margin: 1rem 0 2rem 0;
}
</style>